let query;

const iframeSizer = () => {
  const script = () => {
    const iframe = document.getElementById("pardot-form");
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    if (iframe) {
      eventer(
        messageEvent,
        (e) => {
          if (isNaN(e.data)) return;
          iframe.style.height = e.data + "px";
        },
        false
      );
    }
  };
  return script();
};

const gtmTracker = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    originalQuery:
      window.location && window.location.search !== query
        ? window.location
        : window.location.href,
  });
};

export const onClientEntry = () => {
  query = window.location ? window.location.search : undefined;
};

export const onRouteUpdate = ({ location }) => {
  iframeSizer();
  gtmTracker();
  if (typeof window.piTracker !== "undefined") window.piTracker(location);
};
